import { Typography } from "antd";

function Intro() {
  return (
    <>
      <Typography.Title level={3}>Başvuru Şartları</Typography.Title>
      <Typography.Paragraph>
        Eğitim bilimleri temel alanında başvurulan doçentlik bilim alanı ile
        ilgili olarak aşağıdaki çalışmalara verilen birim puanlar esas alınmak
        suretiyle; en az doksan (90) puanının doktora unvanının alınmasından
        sonra gerçekleştirilen çalışmalardan elde edilmiş olması kaydıyla,
        asgari yüz (100) puan karşılığı bilimsel etkinlikte bulunmuş olması
        gerekir. Her çalışma Tablo 1' de sadece bir bölümde yazılarak
        puanlandırılır.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Tek yazarlı yayınlarda yazar tam puan alır. Çok yazarlı yayınlarda puan
        yazarlar arasında eşit olarak bölünür.
      </Typography.Paragraph>
    </>
  );
}

export default Intro;
