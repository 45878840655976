export const CATEGORY_ULUSLARARASI_MAKALE_ELEMENTS = [
  {
    name: "uluslararasi-makale-1-20",
    value: "a",
    point: "20",
    question:
      "SSCI, SCI, SCI- Expanded ve AHCI kapsamındaki dergilerde editöre\n" +
      "mektup, özet veya kitap kritiği hariç olmak üzere yayımlanmış makale",
  },
  {
    name: "uluslararasi-makale-1-15",
    value: "b",
    point: "15",
    question:
      " Uluslararası alan endekslerinde taranan dergilerde editöre mektup, özet\n" +
      "veya kitap kritiği hariç olmak üzere yayımlanmış makale",
  },
  {
    name: "uluslararasi-makale-1-5",
    value: "c",
    point: "5",
    question:
      "Bu maddenin a veya b bentleri kapsamındaki yayınlarda alanında bilime\n" +
      "katkı sağlayan kitap kritiği yapılmış makale",
  },
];

export const CATEGORY_ULUSAL_MAKALE_ELEMENTS = [
  {
    name: "ulusal-makale-1-8",
    value: "a",
    point: "8",
    question:
      "ULAKBİM tarafından taranan ulusal hakemli dergilerde yayımlanmış\n" +
      "makale",
  },
  {
    name: "ulusal-makale-1-4",
    value: "b",
    point: "4",
    question: "a bendi dışındaki ulusal hakemli dergilerde yayımlanmış makale",
  },
];

export const CATEGORY_LISANSUSTU_YAYIN_ELEMENTS = [
  {
    name: "lisanustu-uretilmis-yayin-1-10",
    value: "a",
    point: "10",
    question: "Uluslararası yayınevleri tarafından yayımlanmış kitap",
  },
  {
    name: "lisanustu-uretilmis-yayin-1-8",
    value: "b",
    point: "8",
    question: "Uluslararası yayınevleri tarafından yayımlanmış kitapta bölüm",
  },
  {
    name: "lisanustu-uretilmis-yayin-1-5",
    value: "c",
    point: "5",
    question: "Ulusal yayınevleri tarafından yayımlanmış kitap",
  },
  {
    name: "lisanustu-uretilmis-yayin-1-4",
    value: "d",
    point: "4",
    question: "Ulusal yayınevleri tarafından yayımlanmış kitapta bölüm",
  },
  {
    name: "lisanustu-uretilmis-yayin-1-8",
    value: "e",
    point: "8",
    question:
      "SSCI, SCI, SCI-Expanded ve AHCI kapsamındaki dergilerde yayımlanmış\n" +
      "makale",
  },
  {
    name: "lisanustu-uretilmis-yayin-1-6",
    value: "f",
    point: "6",
    question:
      "Uluslararası alan endekslerinde taranan dergilerde yayımlanmış makale",
  },
  {
    name: "lisanustu-uretilmis-yayin-1-4",
    value: "g",
    point: "4",
    question: "ULAKBİM tarafından taranan dergilerde yayımlanmış makale",
  },
];

export const CATEGORY_KITAP_ELEMENTS = [
  {
    name: "kitap-1-20",
    value: "a",
    point: "20",
    question: "Uluslararası yayınevleri tarafından yayımlanmış kitap",
  },
  {
    name: "kitap-1-10",
    value: "b",
    point: "10",
    question:
      "Uluslararası yayınevleri tarafından yayımlanmış kitap editörlüğü veya\n" +
      "bölüm yazarlığı",
  },
  {
    name: "kitap-1-15",
    value: "c",
    point: "15",
    question: "Ulusal yayınevleri tarafından yayımlanmış kitap",
  },
  {
    name: "kitap-1-8",
    value: "d",
    point: "8",
    question:
      "Ulusal yayınevleri tarafından yayımlanmış kitap editörlüğü veya bölüm\n" +
      "yazarlığı",
  },
];

export const CATEGORY_ATIFLAR_ELEMENTS = [
  {
    name: "atiflar-1-3",
    value: "a",
    point: "3",
    question:
      " SCI, SCI-Expanded, SSCI ve AHCI tarafından taranan dergilerde;\n" +
      "Uluslararası yayınevleri tarafından yayımlanmış kitaplarda yayımlanan ve\n" +
      "adayın yazar olarak yer almadığı yayınlardan her birinde, metin içindeki\n" +
      "atıf sayısına bakılmaksızın adayın atıf yapılan her eseri için",
  },
  {
    name: "atiflar-2-3",
    value: "b",
    point: "3",
    question:
      "SCI, SCI-Expanded, SSCI ve AHCI dışındaki endeksler tarafından taranan\n" +
      "dergilerde; Uluslararası yayınevleri tarafından yayımlanmış kitaplarda\n" +
      "bölüm yazarı olarak yayımlanan ve adayın yazar olarak yer almadığı\n" +
      "yayınlardan her birinde, metin içindeki atıf sayısına bakılmaksızın adayın\n" +
      "atıf yapılan her eseri için",
  },
  {
    name: "atiflar-3-1",
    value: "c",
    point: "1",
    question:
      "Ulusal hakemli dergilerde; Ulusal yayınevleri tarafından yayımlanmış\n" +
      "kitaplarda yayımlanan ve adayın yazar olarak yer almadığı yayınlardan her\n" +
      "birinde, metin içindeki atıf sayısına bakılmaksızın adayın atıf yapılan her\n" +
      "eseri için",
  },
];

export const CATEGORY_LISANSUSTU_ELEMENTS = [
  {
    name: "lisansustu-1-4",
    value: "a",
    point: "4",
    question: "Doktora tez danışmanlığı",
  },
  {
    name: "lisansustu-2-2",
    value: "b",
    point: "2",
    question: "Yüksek lisans tez danışmanlığı",
  },
];

export const CATEGORY_BILIMSEL_ARASTIRMA_ELEMENTS = [
  {
    name: "bilimsel-arastirma-1-15",
    value: "a",
    point: "15",
    question:
      "Devam eden veya başarı ile tamamlanmış AB Çerçeve Programı Bilimsel\n" +
      "Araştırma Projesinde koordinatör / baş araştırmacı olmak",
  },
  {
    name: "bilimsel-arastirma-2-10",
    value: "b",
    point: "10",
    question:
      "Devam eden veya başarı ile tamamlanmış AB Çerçeve programı bilimsel\n" +
      "araştırma projesinde ortak araştırmacı olmak",
  },
  {
    name: "bilimsel-arastirma-3-6",
    value: "c",
    point: "6",
    question:
      "Devam eden veya başarı ile tamamlanmış a ve b bentleri dışındaki\n" +
      "uluslararası destekli bilimsel araştırma projelerinde (derleme ve rapor\n" +
      "hazırlama çalışmaları hariç) görev almak",
  },
  {
    name: "bilimsel-arastirma-4-4",
    value: "d",
    point: "4",
    question:
      "Üniversiteler dışındaki kamu kurumlarıyla yapılan başarıyla tamamlanan\n" +
      "veya yürütülen bilimsel araştırma projelerinde görev almak ",
  },
];

export const CATEGORY_BILIMSEL_TOPLANTI_ELEMENTS = [
  {
    name: "bilimsel-toplanti-1-3",
    value: "a",
    point: "3",
    question:
      "Uluslararası bilimsel toplantılarda sunulan (poster hariç), tam metni\n" +
      "veya özeti matbu veya elektronik olarak bildiri kitapçığında yayımlanmış\n" +
      "çalışmalar.",
  },
  {
    name: "bilimsel-toplanti-2-2",
    value: "b",
    point: "2",
    question:
      "Ulusal bilimsel toplantılarda sunulan (poster hariç), tam metni veya\n" +
      "özeti matbu veya elektronik olarak bildiri kitapçığında yayımlanmış\n" +
      "çalışmalar.",
  },
];

export const CATEGORY_EGITIM_OGRETIM_ELEMENTS = [
  {
    name: "egitim-ogretim-1-3",
    value: "a",
    point: "3",
    question: "Bir dönem yüksek lisans veya doktora dersi",
  },
  {
    name: "egitim-ogretim-2-2",
    value: "b",
    point: "2",
    question: "Bir dönem önlisans veya lisans dersi",
  },
];
