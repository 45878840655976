import { useState } from "react";
import { Form, Checkbox, Select } from "antd";

function QuestionRow({ question, point, value, name }) {
  const [selected, setSelected] = useState(false);

  return (
    <div className="question-row">
      <Checkbox value={value} onChange={() => setSelected(!selected)}>
        {question}
        <div className="point"> ({point} Puan)</div>
      </Checkbox>
      {selected && (
        <Form.Item
          name={name}
          label="Kaç Tane"
          className="sub-question-row"
          rules={[{ required: true, message: "Lütfen adet seçiniz" }]}
        >
          <Select placeholder="Seçiniz">
            {[...Array(30)].map((_, i) => (
              <Select.Option value={i + 1}>{i + 1}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </div>
  );
}

export default QuestionRow;
