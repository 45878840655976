import { Table, Typography, Result } from "antd";
import { RESULT_TABLE_COLUMNS } from "../../constants/commons";

function Summary({ dataSource }) {
  return (
    <div style={{ marginTop: 18 }}>
      <Table
        columns={RESULT_TABLE_COLUMNS}
        dataSource={dataSource}
        size="small"
        pagination={false}
        summary={(pageData) => {
          let totalRawScore = 0;
          let totalLimitedScore = 0;

          pageData.forEach(({ rawScore, limitedScore }) => {
            totalRawScore += rawScore;
            totalLimitedScore += limitedScore;
          });

          const isSuccess = totalLimitedScore >= 100;

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <b>Toplam</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Typography.Text>{totalRawScore}</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Typography.Text type={isSuccess ? "success" : "danger"}>
                    {totalLimitedScore}/100
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3} align="center">
                  <Result
                    status={isSuccess ? "success" : "error"}
                    title={
                      isSuccess
                        ? "Asgari Koşulları Sağlamaktasınız"
                        : "Asgari Koşulları Sağlamamaktasınız"
                    }
                  />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
}

export default Summary;
