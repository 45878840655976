import { Layout } from "antd";

function MainLayout({ children }) {
  return (
    <Layout>
      <Layout.Header className="header">
        <div className="header-title">
          Eğitim Bilimleri Temel Alanı Hesaplama Aracı
        </div>
      </Layout.Header>
      <Layout.Content className="site-layout">
        <div className="inner-content">{children}</div>
      </Layout.Content>
    </Layout>
  );
}

export default MainLayout;
