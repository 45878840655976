import { useState } from "react";
import { Select, Divider, Form, Button, Checkbox } from "antd";

import QuestionRow from "./components/QuestionRow";
import Intro from "./components/Intro";
import Info from "./components/Info";
import Summary from "./components/Summary";
import MainLayout from "./layouts/MainLayout";
import { BILIM_ALAN_VALUES } from "./constants/commons";
import {
  CATEGORY_ATIFLAR_ELEMENTS,
  CATEGORY_LISANSUSTU_ELEMENTS,
  CATEGORY_BILIMSEL_ARASTIRMA_ELEMENTS,
  CATEGORY_BILIMSEL_TOPLANTI_ELEMENTS,
  CATEGORY_EGITIM_OGRETIM_ELEMENTS,
  CATEGORY_ULUSLARARASI_MAKALE_ELEMENTS,
  CATEGORY_ULUSAL_MAKALE_ELEMENTS,
  CATEGORY_LISANSUSTU_YAYIN_ELEMENTS,
  CATEGORY_KITAP_ELEMENTS,
} from "./constants/formElements";
import "./styles/main.css";

function App() {
  const [finish, setFinish] = useState(false);
  const [formValues, setFormValues] = useState({});

  function calculateCategoryPoint(maxScore, category) {
    let limitedScore = 0;
    let rawScore = 0;

    if (formValues) {
      Object.keys(formValues)
        .filter((item) => item.includes(`${category}-`))
        .map((item) => {
          const selectedCount = formValues[item];
          const point = parseInt(item.split("-").pop());
          const totalPoint = selectedCount * point;
          const currentLimitedScore = limitedScore + selectedCount * point;

          if (currentLimitedScore >= maxScore) {
            limitedScore = maxScore;
          } else {
            limitedScore = currentLimitedScore;
          }

          rawScore = rawScore + totalPoint;
          return "";
        });
    }

    return {
      limitedScore,
      rawScore,
    };
  }

  function getResult() {
    const point = calculateCategoryPoint(20, "uluslararasi-makale");
    const point2 = calculateCategoryPoint(12, "ulusal-makale");
    const point3 = calculateCategoryPoint(10, "lisanustu-uretilmis-yayin");
    const point4 = calculateCategoryPoint(53, "kitap");
    const point5 = calculateCategoryPoint(20, "atiflar");
    const point6 = calculateCategoryPoint(10, "lisansustu");
    const point7 = calculateCategoryPoint(20, "bilimsel-arastirma");
    const point8 = calculateCategoryPoint(10, "bilimsel-toplanti");
    const point9 = calculateCategoryPoint(4, "egitim-ogretim");

    return [
      {
        key: "1",
        title: "1. Uluslararası Makale (Limit: 20)",
        rawScore: point.rawScore,
        limitedScore: point.limitedScore,
      },
      {
        key: "2",
        title: "2. Ulusal Makale (Limit: 12)",
        rawScore: point2.rawScore,
        limitedScore: point2.limitedScore,
      },
      {
        key: "3",
        title: "3. Lisansüstü Tezlerden Üretilmiş Yayın (Limit: 10)",
        rawScore: point3.rawScore,
        limitedScore: point3.limitedScore,
      },
      {
        key: "4",
        title: "4. Kitap (Limit: 53)",
        rawScore: point4.rawScore,
        limitedScore: point4.limitedScore,
      },
      {
        key: "5",
        title: "5. Atıflar (Limit: 20)",
        rawScore: point5.rawScore,
        limitedScore: point5.limitedScore,
      },
      {
        key: "6",
        title: "6. Lisansüstü Tez Danışmanlığı (Limit: 10)",
        rawScore: point6.rawScore,
        limitedScore: point6.limitedScore,
      },
      {
        key: "7",
        title: "7. Bilimsel Araştırma Projesi (Limit: 20)",
        rawScore: point7.rawScore,
        limitedScore: point7.limitedScore,
      },
      {
        key: "8",
        title: "8. Bilimsel Toplantı Faaliyeti (Limit: 10)",
        rawScore: point8.rawScore,
        limitedScore: point8.limitedScore,
      },
      {
        key: "9",
        title: "9. Eğitim-Öğretim Faaliyeti (Limit: 4)",
        rawScore: point9.rawScore,
        limitedScore: point9.limitedScore,
      },
    ];
  }

  function onFinish(values) {
    setFormValues(values);
    setFinish(true);
  }

  return (
    <MainLayout>
      <Intro />
      <Divider />
      <Form
        name="alan"
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          label="Bilim Alanı Seçiniz"
          name="alan"
          rules={[{ required: true, message: "Lütfen Bilim Alanı Seçiniz" }]}
          className="form-box"
        >
          <Select placeholder="Seçiniz">
            {BILIM_ALAN_VALUES.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="1. Uluslararası Makale"
          tooltip="Başvurulan doçentlik bilim alanı ile ilgili ve adayın hazırladığı lisansüstü tezlerden üretilmemiş olmak kaydıyla"
          name="uluslararasi-makale"
          className="form-box"
        >
          <Checkbox.Group>
            {CATEGORY_ULUSLARARASI_MAKALE_ELEMENTS.map(
              ({ name, value, point, question }) => (
                <QuestionRow
                  key={value}
                  name={name}
                  value={value}
                  point={point}
                  question={question}
                />
              )
            )}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label="2. Ulusal Makale"
          tooltip="Başvurulan doçentlik bilim alanı ile ilgili ve adayın yaptığı lisansüstü tezlerden üretilmemiş olmak kaydıyla"
          name="ulusal-makale"
          className="form-box"
        >
          <Checkbox.Group>
            {CATEGORY_ULUSAL_MAKALE_ELEMENTS.map(
              ({ name, value, point, question }) => (
                <QuestionRow
                  key={value}
                  name={name}
                  value={value}
                  point={point}
                  question={question}
                />
              )
            )}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label="3. Lisansüstü Tezlerden Üretilmiş Yayın"
          tooltip="Adayın hazırladığı lisansüstü tezleriyle ilgili olmak kaydıyla"
          name="lisanustu-uretilmis-yayin"
          className="form-box"
          rules={[
            { required: true, message: "En az 1 adet yayın seçilmelidir" },
          ]}
        >
          <Checkbox.Group>
            {CATEGORY_LISANSUSTU_YAYIN_ELEMENTS.map(
              ({ name, value, point, question }) => (
                <QuestionRow
                  key={value}
                  name={name}
                  value={value}
                  point={point}
                  question={question}
                />
              )
            )}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label="4.Kitap"
          tooltip="Adayın hazırladığı lisansüstü tezlerinden üretilmemiş ve başvurulan doçentlik bilim alanı ile ilgili olmak kaydıyla"
          name="kitap"
          className="form-box"
        >
          <Checkbox.Group>
            {CATEGORY_KITAP_ELEMENTS.map(({ name, value, point, question }) => (
              <QuestionRow
                key={value}
                name={name}
                value={value}
                point={point}
                question={question}
              />
            ))}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label="5.Atıflar" name="atiflar" className="form-box">
          <Checkbox.Group>
            {CATEGORY_ATIFLAR_ELEMENTS.map(
              ({ name, value, point, question }) => (
                <QuestionRow
                  key={value}
                  name={name}
                  value={value}
                  point={point}
                  question={question}
                />
              )
            )}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label="6. Lisansüstü Tez Danışmanlığı"
          tooltip="Adayın danışmanlığını yürüttüğü tamamlanan lisansüstü tezlerden"
          name="lisansustu"
          className="form-box"
        >
          <Checkbox.Group>
            {CATEGORY_LISANSUSTU_ELEMENTS.map(
              ({ name, value, point, question }) => (
                <QuestionRow
                  key={value}
                  name={name}
                  value={value}
                  point={point}
                  question={question}
                />
              )
            )}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label="7. Bilimsel Araştırma Projesi"
          name="bilimse-arastirma"
          className="form-box"
        >
          <Checkbox.Group>
            {CATEGORY_BILIMSEL_ARASTIRMA_ELEMENTS.map(
              ({ name, value, point, question }) => (
                <QuestionRow
                  key={value}
                  name={name}
                  value={value}
                  point={point}
                  question={question}
                />
              )
            )}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label="8. Bilimsel Toplantı Faaliyeti"
          tooltip="Başvurulan bilim alanı ile ilgili ve adayın hazırladığı lisansüstü tezlerden üretilmemiş olmak kaydıyla"
          name="bilimse-toplanti"
          className="form-box"
        >
          <Checkbox.Group>
            {CATEGORY_BILIMSEL_TOPLANTI_ELEMENTS.map(
              ({ name, value, point, question }) => (
                <QuestionRow
                  key={value}
                  name={name}
                  value={value}
                  point={point}
                  question={question}
                />
              )
            )}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label="9. Eğitim-Öğretim Faaliyeti"
          tooltip="Doktora eğitimini tamamladıktan sonra açık, uzaktan veya yüz yüze ortamlarda verilmiş ders"
          name="egitim-ogretim"
          className="form-box"
        >
          <Checkbox.Group>
            {CATEGORY_EGITIM_OGRETIM_ELEMENTS.map(
              ({ name, value, point, question }) => (
                <QuestionRow
                  key={value}
                  name={name}
                  value={value}
                  point={point}
                  question={question}
                />
              )
            )}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item className="form-box">
          <Button type="primary" htmlType="submit" size="large" block>
            Hesapla
          </Button>
          {finish && <Summary dataSource={getResult()} />}
        </Form.Item>
      </Form>
      <Divider />
      <Info />
    </MainLayout>
  );
}

export default App;
