import { Collapse, Typography } from "antd";

function Info() {
  return (
    <div className="form-box">
      <Typography.Title level={5}>
        Tablo 1 - Eğitim Bilimleri Temel Alanı
      </Typography.Title>
      <Collapse className="collapse-wrapper">
        <Collapse.Panel header="1. Kısaltmalar" key="1">
          <ol>
            <li>SCI–Expanded : Science Citation Index-Expanded</li>
            <li>SCI : Science Citation Index</li>
            <li>AHCI : Art and Humanities Index</li>
            <li>ULAKBİM : Ulusal Akademik Ağ ve Bilgi Merkezi</li>
            <li>
              AB Çerçeve Programları : AB tarafından, üye ve aday ülkelerin
              çeşitli alanlardaki ulusal politika ve uygulamalarının birbirine
              yakınlaştırılması amacıyla oluşturulan Topluluk Programlarından
              birisidir
            </li>
          </ol>
          <div>
            * Alan İndeksleri= Isı Database'e giren eğitimle ilgili indeksler,
            Australian Education Index, British Education Index, Journals
            Indexed in ERIC, Education Full Text (H. W. Wilson) Database
            Covarage List
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="2. Tanımlar" key="2">
          <p>
            <b>Ulusal Yayınevi:</b> En az dört yıl ulusal düzeyde düzenli
            faaliyet yürüten, yayınları Türkiye’deki üniversite kütüphanelerinde
            kataloglanan ve daha önce aynı alanda farklı yazarlara ait en az 20
            kitap yayımlamış yayınevi.
          </p>
          <p>
            <b>Uluslararası Yayınevi:</b> En az dört yıl uluslararası düzeyde
            düzenli faaliyet yürüten, yayımladığı kitaplar Yükseköğretim
            Kurulunca tanınan sıralama kuruluşlarınca belirlenen dünyada ilk
            500’e giren üniversite kütüphanelerinde kataloglanan ve aynı alanda
            farklı yazarlara ait en az 20 kitap yayımlamış olan yayınevi.
          </p>
          <p>
            <b>Uluslararası Bilimsel Toplantı:</b> Farklı ülkelerden bilim
            insanlarının bilim kurulunda bulunduğu ve sunumların bilimsel ön
            incelemeden geçirilerek kabul edildiği toplantı.
          </p>
          <p>
            <b>Ulusal Bilimsel Toplantı:</b> Ulusal seviyede farklı kurumlardan
            bilim insanlarının bilim insanlarının bilim kurulunda bulunduğu ve
            sunumların bilimsel ön incelemeden geçirilerek kabul edildiği
            toplantı.
          </p>
          <p>
            <b>Yayımlanmış Makale:</b> Alanında bilime katkı sağlamış olmak
            şartıyla özgün, matbu veya elektronik ortamda yayımlanmış makale.
          </p>
        </Collapse.Panel>
      </Collapse>
      <Typography.Paragraph strong>
        Aday başvurusunda, bir bilim alanı ve bilim alanına bağlı en az 1 (bir)
        en fazla 3 (üç) anahtar kelime belirtmelidir.
      </Typography.Paragraph>
      <Typography.Paragraph>
        *<b>Not:</b> Sınıf eğitimi alanından doktora derecesine sahip olanlar
        bilim alanı olarak temel eğitimi ve kendi çalışma alanlarına uygun
        anahtar kelimeleri seçeceklerdir. Aynı durum temel eğitimin diğer
        programları olan fen bilgisi, sosyal bilgiler ve matematik eğitimi
        programlarında doktora yapanlar için de geçerlidir.
      </Typography.Paragraph>
    </div>
  );
}

export default Info;
