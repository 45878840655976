export const RESULT_TABLE_COLUMNS = [
  {
    title: "Faaliyet Türü",
    dataIndex: "title",
  },
  {
    title: "Ham Puanınız",
    dataIndex: "rawScore",
  },
  {
    title: "Limitli Puanınız",
    dataIndex: "limitedScore",
  },
];

export const BILIM_ALAN_VALUES = [
  {
    value: "113",
    label: "Bilgisayar ve Öğretim Teknolojileri Eğitimi",
  },
  {
    value: "104",
    label: "Din Kültürü ve Ahlak Bilgisi Eğitimi",
  },
  {
    value: "102",
    label: "Eğitim Bilimleri",
  },
  {
    value: "105",
    label: "Güzel Sanatlar Eğitimi",
  },
  {
    value: "114",
    label: "Matematik ve Fen Bilimleri Eğitimi",
  },
  {
    value: "108",
    label: "Özel Eğitim",
  },
  {
    value: "109",
    label: "Rehberlik ve Psikolojik Danışmanlık",
  },
  {
    value: "115",
    label: "Sosyal Bilimler Eğitimi",
  },
  {
    value: "110",
    label: "Temel Eğitim*",
  },
  {
    value: "111",
    label: "Türkçe Eğitimi",
  },
  {
    value: "112",
    label: "Yabancı Dil Eğitimi",
  },
];
